.imgStyle{
    height: 100vh;
}

.title{
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 32px;
}