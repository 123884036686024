.imgStyle{
    height: 100vh;
}
.forgotPass{
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.title{
    max-width: 384px;
    font-family: Mulish_Regular,sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 60px;
}

.lineDiv{
    height: 1px;
    background-color: #B38B62;
    margin-top: 81px;
    margin-bottom: 24px;
}

.btnsDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftBtns{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.vectorImg{
    margin-right: 8px;
}

.back{
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

.rightBtn{
    padding: 12px 40px;
    background: #B38B62;
    border-radius: 8px;
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;

}