.control{
    margin-bottom: 16px;
}
.control label {
    display: block;
    font-family:Mulish_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 4px;
}
.inputDiv{
    position: relative;
}
.control input,
.select {
    max-width: 545px;
    width: 100%;
    padding: 12px;
    font-family: Mulish_Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    background-color: #181818;
    border: 1px solid #B38B62;
    border-radius: 4px;
}
.control input:focus{
    outline: none;
    color: #FFFFFF;
}
.control input::placeholder{
    color: #FFFFFF;
}
.invalid{
    border: 2px solid #CB2D25 !important;
}
.textDanger{
    font-family:Mulish_Regular, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #CB2D25;
    margin-top: 4px;
    max-width: 545px;
    width: 100%;
}
.inputDiv img{
    width: 25px;
    position: absolute;
    bottom: 11px;
    right: 35px;
    cursor:pointer;
}
.redOne{
    color:#CB2D25;
    margin-left: 5px;
}
@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .control label {
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 14px;
    }
    .control input,
    .select {
        font-size: 22px;
        line-height: 25px;
    }
}
@media only screen and (min-width:768px) and (max-width: 1024px) {
    .control label {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 14px;
    }
    .control input,
    .select {
        font-size: 20px;
        line-height: 22px;
    }
    .textDanger{
        font-size: 14px;
        line-height: 22px;
    }
}
@media only screen and (min-width:425px) and (max-width: 768px) {
    .control{
        margin-bottom: 22px;
    }
    .control label {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 14px;
    }
    .control input,
    .select {
        font-size: 18px;
        line-height: 20px;
    }
    .textDanger{
        font-size: 14px;
        line-height: 22px;
    }
}
@media only screen and (max-width: 425px) {
    .control{
        margin-bottom: 22px;
    }
    .control label {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 14px;
    }
    .control input,
    .select {
        font-size: 18px;
        line-height: 20px;
    }
    .textDanger{
        font-size: 14px;
        line-height: 22px;
    }
}
