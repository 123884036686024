h1,
p {
    font-family: Lato;
}
.parent {
    width: 100%;
    max-width:1500px;
    /*height: 100px !important;*/
    overflow: hidden;
}
.chartContainer {
    width: 100%;
    max-width:1500px;
    /*width: 400px;*/
    height: 300px;
    /*max-width:1500px;*/
}

* {
    box-sizing: border-box;
}
img {
    max-width: 100%;
}
body{
    background-color: #181818;
    /*min-height: 100vh;*/
    width:100%;
}
html{
    scroll-behavior: smooth;
}

.container{
    max-width: 1920px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    z-index: 10;
}

.whole{
    background: #181818;
    display: flex;
    height: 100vh;
}
.left{
    width: 50%;
}

.right{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@font-face {
    font-family: Mulish_Bold;
    src: url(assets/fonts/Mulish/Mulish_Bold.ttf);
}

@font-face {
    font-family: Mulish_SemiBold;
    src: url(assets/fonts/Mulish/Mulish_SemiBold.ttf);
}
@font-face {
    font-family: Mulish_Medium;
    src: url(assets/fonts/Mulish/Mulish_Medium.ttf);
}

@font-face {
    font-family: Mulish_Regular;
    src: url(assets/fonts/Mulish/Mulish_Regular.ttf);
}
@font-face {
    font-family: Mulish_Light;
    src: url(assets/fonts/Mulish/Mulish_Light.ttf);
}

@font-face {
    font-family: Lato_Light;
    src: url(assets/fonts/Lato/Lato_Light.ttf);
}

@font-face {
    font-family: Lato_Regular;
    src: url(assets/fonts/Lato/Lato_Regular.ttf);
}

@font-face {
    font-family: Lato_Bold;
    src: url(assets/fonts/Lato/Lato_Bold.ttf);
}