.imgStyle{
    height: 100vh;
}

.imgDiv{
    margin-bottom: 24px;
}
.title{
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 48px;
}
.forgotDiv{
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 24px;
    margin-bottom: 20px;
    cursor: pointer;
}

.belowDivLogin{
    margin-top: 32px;
    font-family: Mulish_Regular,sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.signUp{
    font-family: Mulish_Regular,sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 5px;
    color:#B38B62;
    cursor: pointer;
}

.signInError{
    font-family: Mulish_Regular,sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: red;
    text-align: center;
    margin-top: 5px;
}