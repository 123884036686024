.imgStyle{
    height: 100vh;
}
.checkImgDiv{
    margin-bottom: 24px;
}

.checkTitle{
    font-family: Mulish_Bold,sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
    color: #FFFFFF;
    text-align: center;
}

.checkText{
    font-family: Mulish_Regular,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 24px;
    max-width: 462px;
    width: 100%;
}

.checkBtn{
    max-width: 260px;
    width: 100%;
    background: #B38B62;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Mulish_SemiBold,sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    border: none;
    cursor: pointer;

}