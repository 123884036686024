.btnStyle{
    background: #B38B62;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    cursor: pointer;
    font-family: Mulish_SemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    text-align: center;
    margin-top:16px;
}
.btnStyle:disabled{
    cursor: not-allowed;
    background-color:grey
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
@media only screen and (max-width: 768px) {
    .btnStyle{
        font-size: 16px;
        line-height: 20px;
    }
}

